@font-face
  font-family: 'Itsuki-icons'
  src:  url('../../static/images/v2/fonts/Itsuki-icons.eot?nc61zi')
  src:  url('../../static/images/v2/fonts/Itsuki-icons.eot?nc61zi#iefix') format('embedded-opentype'), url('../../static/images/v2/fonts/Itsuki-icons.ttf?nc61zi') format('truetype'), url('../../static/images/v2/fonts/Itsuki-icons.woff?nc61zi') format('woff'), url('../../static/images/v2/fonts/Itsuki-icons.svg?nc61zi#Itsuki-icons') format('svg')
  font-weight: normal
  font-style: normal
  font-display: block

.ico
  font-family: 'Itsuki-icons' !important
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.angel:before 
  content: "\e912"

.family:before
  content: "\e911"

.rect-2:before
  content: "\e910"

.yen-bag:before
  content: "\e90f"

.miniature-bulb:before
  content: "\e90c"

.edit:before
  content: "\e90e"

.info:before
  content: "\e90a"

.chevron-right:before
  content: "\e90b"

.close:before
  content: "\e90d"

.rect:before
  content: "\e900"

.ellipse:before
  content: "\e901"

.user:before
  content: "\e902"

.car:before
  content: "\e903"

.home:before
  content: "\e904"

.polygon:before
  content: "\e905"

.gear:before
  content: "\e906"

.building:before
  content: "\e907"

.yen:before
  content: "\e908"

.heart:before
  content: "\e909"

.circle:before
  content: "\ea56"