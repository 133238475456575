@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap')

html
  height: 100%

body
  background-color: #f5f5f5
  margin: 0
  font-family: sans-serif
  height: 100%

#js-content, #admin-content
  height: 100%
  overflow-x: scroll

@for $i from 1 through 5
  .u-m#{$i * 10}
    margin: #{$i * 10}px

@for $i from 1 through 5
  .u-mt#{$i * 10}
    margin-top: #{$i * 10}px !important

@for $i from 1 through 5
  .u-mr#{$i * 10}
    margin-right: #{$i * 10}px !important

@for $i from 1 through 5
  .u-mb#{$i * 10}
    margin-bottom: #{$i * 10}px !important

@for $i from 1 through 5
  .u-ml#{$i * 10}
    margin-left: #{$i * 10}px !important

@for $i from 1 through 5
  .u-p#{$i * 10}
    padding: #{$i * 10}px

@for $i from 1 through 5
  .u-pt#{$i * 10}
    padding-top: #{$i * 10}px

@for $i from 1 through 5
  .u-pr#{$i * 10}
    padding-right: #{$i * 10}px !important

@for $i from 1 through 5
  .u-pb#{$i * 10}
    padding-bottom: #{$i * 10}px !important

@for $i from 1 through 7
  .u-pl#{$i * 10}
    padding-left: #{$i * 10}px !important

@for $i from 1 through 10
  .u-w#{$i * 50}
    width: #{$i * 50}px

@for $i from 1 through 5
  .u-h#{$i * 10}
    height: #{$i * 10}px

@for $i from 1 through 20
  .u-fs#{$i}
    font-size: #{$i}px !important

.u-full_width
  width: 100%

.u-block
  display: block

.u-flex
  display: flex

.u-bold
  font-weight: bold !important

.u-tal
  text-align: left

.u-tac
  text-align: center

.u-tar
  text-align: right

.hover-bg-8fb6ff:hover
  background-color: #8fb6ff !important